import { SocketChannels } from '../service/socket-channels.enum';

export const actionTypes: { [key: string]: string } = {
  // AUTH/USER
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  SIGNOUT: 'SIGNOUT',
  UPDATE_ME: 'UPDATE_ME',
  CURRENTUSER: 'CURRENT_USER',

  // INSTITUTION
  TOGGLE_INSTITUTION: 'TOGGLE_INSTITUTION',

  // RECORDED VIDEO MODAL
  SET_VIDEO_TITLE_CONFIG: 'SET_VIDEO_TITLE_CONFIG',

  // SESSION
  SET_SESSION_CONFIG: 'SET_SESSION_CONFIG',
  SET_SESSION_RESULT: 'SET_SESSION_RESULT',

  // DEVICES
  SET_DEVICES: 'SET_DEVICES',

  //SOCKETS
  ROOM_JOINED: SocketChannels.RoomJoined,
  ROOM_STATUS: SocketChannels.RoomStatus,
  ROOM_GET_STATUS: SocketChannels.RoomGetStatus,
  START_FEEDBACK: SocketChannels.StartFeedback,
  END_SESSION: SocketChannels.EndSession,
  RECORD_STATUS_UPDATE: SocketChannels.RecordStatusUpdate,
  SESSION_RECORD_MANIPULATED: SocketChannels.SessionRecordManipulated,
  SESSION_RECORD_MANIPULATION_LISTENER: SocketChannels.SessionRecordManipulationListener,
  SESSION_END: 'CLEAR_SESSION_STATE',

  SET_SESSION_TIME_PLAYING: 'SET_SESSION_TIME_PLAYING',
  SESSION_EVALUATION_UPDATE: 'SESSION_EVALUATION_UPDATE',
  SESSION_SCORE_VALUE: 'SESSION_SCORE_VALUE',

  // Insights
  SET_INSIGHTS_USAGE_FILTER_FROM_UNTIL: 'SET_INSIGHTS_USAGE_FILTER_FROM_UNTIL',
  SET_INSIGHTS_CASES_FILTER_FROM_UNTIL: 'SET_INSIGHTS_CASES_FILTER_FROM_UNTIL',
  SET_INSIGHTS_EVALUATION_FILTER_FROM_UNTIL: 'SET_INSIGHTS_EVALUATION_FILTER_FROM_UNTIL',
  SET_INSIGHTS_REPORTING_FILTER_FROM_UNTIL: 'SET_INSIGHTS_REPORTING_FILTER_FROM_UNTIL',

  // Filters
  SET_USER_DATE_REGISTRATION_FILTER_FROM_UNTIL: 'SET_USER_DATE_REGISTRATION_FILTER_FROM_UNTIL',
  SET_USERS_SEARCH_FILTER: 'SET_USERS_SEARCH_FILTER',
  SET_USERS_ROLE_FILTER: 'SET_USERS_ROLE_FILTER',
  SET_USERS_SORT_BY: 'SET_USERS_SORT_BY',
  SET_SCORING_ITEM_FILTER: 'SET_SCORING_ITEM_FILTER',
  SET_SCORING_ITEM_CATEGORIES_SELECTOR: 'SET_SCORING_ITEM_CATEGORIES_SELECTOR',
  SET_CASE_SEARCH_FILTER: 'SET_CASE_SEARCH_FILTER',
  SET_CASE_CATEGORIES_SELECTOR: 'SET_CASE_CATEGORIES_SELECTOR',
  SET_CATEGORY_SEARCH: 'SET_CATEGORY_SEARCH',
  SET_SCHEDULE_CASES_FILTER: 'SET_SCHEDULE_CASES_FILTER',
  SET_SCHEDULE_CATEGORIES_FILTER: 'SET_SCHEDULE_CATEGORIES_FILTER',
  SET_ONLY_SCHEDULE: 'SET_ONLY_SCHEDULE',
  SET_ONLY_MINE: 'SET_ONLY_MINE',
  SET_LIVE_SESSION_CASE_SELECTOR: 'SET_LIVE_SESSION_CASE_SELECTOR',
  SET_LIVE_SESSION_CATEGORY_SELECTOR: 'SET_LIVE_SESSION_CATEGORY_SELECTOR',
  SET_HISTORY_CASE_SELECTOR: 'SET_HISTORY_CASE_SELECTOR',
  SET_HISTORY_CATEGORY_SELECTOR: 'SET_HISTORY_CATEGORY_SELECTOR',
  SET_SESSION_LOG_CASE_SELECTOR: 'SET_SESSION_LOG_CASE_SELECTOR',
  SET_SESSION_LOG_CATEGORY_SELECTOR: 'SET_SESSION_LOG_CATEGORY_SELECTOR',
  SET_SESSION_LOG_COACH_FILTER: 'SET_SESSION_LOG_COACH_FILTER',
  SET_SESSION_LOG_TRAINEE_FILTER: 'SET_SESSION_LOG_TRAINEE_FILTER',
  SET_INSTITUTION_SEARCH: 'SET_INSTITUTION_SEARCH',
  SET_TRAINEE_EVALUATION_SEARCH: 'SET_TRAINEE_EVALUATION_SEARCH',
  SET_COACH_EVALUATION_SEARCH: 'SET_COACH_EVALUATION_SEARCH',
  SET_CASE_EVALUATION: 'SET_CASE_EVALUATION',
  SET_REPORTING_SEARCH: 'SET_REPORTING_SEARCH',
  SET_SESSION_SCORE_RESULT: 'SET_SESSION_SCORE_RESULT',
  SET_SESSION_STATUS: 'SET_SESSION_STATUS',
  SET_SESSION_TEACHABLE_MOMENTS: 'SET_SESSION_TEACHABLE_MOMENTS',
  SET_SESSION_TOTAL_SCORE: 'SET_SESSION_TOTAL_SCORE',
  SET_FEEDBACK_IS_VISIBLE: 'SET_FEEDBACK_IS_VISIBLE',
};
