import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Alert from '../../utils/alert';
import { actionSetRecordStatus, actionSetSessionConfig, setSessionScoreResult } from '../../redux/actions';
import { IRouteComponent } from '../../routes';
import { CaseService } from '../../service/case.service';
import { CaseResponseDto } from '../../service/dto/case.dto';
import { SessionResponseDto } from '../../service/dto/session.dto';
import { SessionService } from '../../service/session.service';
import { retryOperation, retryOperationAsync } from '../../utils/funcs';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import VideoChat from './Video/VideoChat';
import { SessionStatus } from '../../common/enums';

// This is view, when user is joining session video call
const SessionStream = ({ currentUser }: IRouteComponent) => {
  const { isLoaded: isSessionLoaded, session }: { session: SessionResponseDto; isLoaded: boolean } = useAppSelector(
    (state: any) => state.sessionConfig,
  );
  const dispatch = useAppDispatch();
  const { id, shareToken } = useParams<{ id: string; shareToken: string }>();
  const { t } = useTranslation();
  const [caseData, setCaseData] = useState<CaseResponseDto>();
  const [ready, setReady] = useState(false);

  const caseServiceDataFetch = useCallback((caseId: string) => {
    CaseService.getById(
      caseId,
      (data: CaseResponseDto) => {
        setCaseData({ ...data, scoring_items_positions: new Map(Object.entries(data.scoring_items_positions)) });
      },
      () => {
        console.error('issue retrieving the session');
      },
    );
  }, []);

  const serviceFetchSession = useCallback((sessionId: string): Promise<SessionResponseDto> => {
    return new Promise((resolve, reject) => {
      const getSessionToPromise = () =>
        new Promise<SessionResponseDto>((innerResolve, innerReject) => 
          SessionService.getById(sessionId, innerResolve, innerReject)
        ).then((data: SessionResponseDto) => {
          dispatch(actionSetSessionConfig({ start: true, session: data }));
          dispatch(setSessionScoreResult(data.scoreResult));
          caseServiceDataFetch(data.caseTemplateSummary.id);
          if (data.dailyCoachingSessionRecord) {
            dispatch(actionSetRecordStatus({ sessionRecord: data.dailyCoachingSessionRecord }));
          }
          resolve(data);
          return data;
        });
      retryOperation(() => getSessionToPromise()).catch(reject);
    });
  }, [dispatch, caseServiceDataFetch]);

  const startCoaching = useCallback(async () => {
    if (!id) {
      throw new Error('Session ID is undefined');
    }
    const {session, room} = await SessionService.startSessionPromise(id);
    dispatch(actionSetSessionConfig({ start: true, session: session }));
    dispatch(setSessionScoreResult(session.scoreResult));
  }, [id, currentUser]);

  useEffect(() => {
    const fetchSessionAndToken = async () => {
      try {
        if (!id) {
          throw new Error('Session ID is undefined');
        }
        const session_ = await serviceFetchSession(id);
        if (session_.status === SessionStatus.Scheduled) {
          await retryOperationAsync(() => startCoaching());
        }

        setReady(true);
      } catch (error) {
        console.error('Error fetching session or Daily token:', error);
        Alert.error(t('errorMessages.sessionInitializationFailed'));
      }
    };

    fetchSessionAndToken();
  }, [id, serviceFetchSession, t]);

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      {isSessionLoaded && caseData && session && ready && (
        <VideoChat
          caseData={caseData} 
          currentUser={currentUser} 
          shareToken={shareToken ?? ''} 
        />
      )}
    </div>
  );
};

export default SessionStream;
function setSessionRecord(dailyCoachingSessionRecord: import("../../service/dto/session.dto").SessionRecordResponseDto | undefined): any {
  throw new Error('Function not implemented.');
}

