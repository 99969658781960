import React, { useState, useCallback, useEffect } from 'react';
import { io, Socket } from 'socket.io-client';
import { SocketChannels } from '../service/socket-channels.enum';
import { COMPONENT_STATE, SessionStatus } from '../common/enums';
import {
  actionSetEndSession,
  actionSetRecordStatus,
  actionSetRoomStatus,
  actionSetSessionManipulationListener,
  actionSetStartFeedback,
  setEndSession,
  setRecordStatus,
  setRoomStatus,
  setSessionManipulationListener,
  setSessionScoreResult, setSessionScoreValue,
  setSessionStatus,
  setStartFeedback,
} from '../redux/actions';
import { StartFeedbackPayload } from '../module/schedule/socket-start-feedback-payload.interface';
import {ScoreResultDto, SessionRecordResponseDto} from '../service/dto/session.dto';
import { SocketSessionRecordManipulated } from '../module/schedule/socket-session-record-manipulated.interface';
import { WsType } from '../common/interfaces';
import { actionCertificationRoomUpdate } from '../module/certification/actions';
import Alert from '../utils/alert';
import {useAppDispatch, useAppSelector} from '../redux/store';

const WebSocketContext = React.createContext<WsType | null>(null);

export default ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [ws, setWs] = useState<WsType | null>(null);

  useEffect(() => {
    const currentUser = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') || '')
      : null;

    const newSocket = io(process.env.API_URL_PREFIX as string, {
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
    });

    const joinRoom = (sessionId: string, userId: string) => {
      newSocket.emit(SocketChannels.RoomJoined, { sessionId, userId });
    };

    const getRoomStatus = (sessionId: string, userId: string) => {
      newSocket.emit(SocketChannels.RoomGetStatus, { sessionId, userId });
    };

    const sessionRecordManipulated = (record: SocketSessionRecordManipulated) => {
      newSocket.emit(SocketChannels.SessionRecordManipulated, record);
    };

    const coachSubscribeToAdHoc = (userId: string) => {
      newSocket.emit(SocketChannels.SubscribeCoachToAdHoc, userId);
    };

    const coachUpdateRating = (sessionId: string, scoreId: string, scoreValue: number, tmstmp: number) => {
      newSocket.emit(SocketChannels.RatingUpdate, { sessionId, scoreId, scoreValue, tmstmp });
    };

    const emitDisconnect = () => {
      newSocket.emit(SocketChannels.Disconnect);
    };

    newSocket.on(SocketChannels.RoomStatus, (data: any) => {
      if (data.clients?.length === 2) {
        dispatch(actionSetRoomStatus({ componentState: COMPONENT_STATE.STREAM }));
      } else if (data.clients?.length === 1) {
        dispatch(actionSetRoomStatus({ componentState: COMPONENT_STATE.WAITING }));
      }
    });

    newSocket.on(SocketChannels.TraineeJoinAdHocSession, (data: any) => {
      Alert.success(
        `Trainee has joined your live session, click button <a href='#/session/details/${data.sessionId}' style='
          text-decoration: underline;
          font-weight: 700;
          font-size: 15px;'>here</a> to join as well.`,
        { html: true, pauseOnHover: true },
      );
    });

    newSocket.on(SocketChannels.StartFeedback, (data: StartFeedbackPayload) => {
      dispatch(setSessionStatus(SessionStatus.Feedback));
    });

    newSocket.on(SocketChannels.RatingUpdate, (sessionId: string, scoreId: string, scoreValue: number, tmstmp: number) => {
      dispatch(setSessionScoreValue({
        sessionId,
        score: {
          [scoreId]: {
            value: scoreValue,
            tmstmp,
          },
        },
      }));
    });

    newSocket.on(SocketChannels.EndSession, () => {
      dispatch(actionSetEndSession({ sessionStatus: SessionStatus.Finished }));
    });

    newSocket.on(SocketChannels.RecordStatusUpdate, (updatedRecord: SessionRecordResponseDto) => {
      dispatch(actionSetRecordStatus({ sessionRecord: updatedRecord }));
    });

    newSocket.on(SocketChannels.SessionRecordManipulationListener, (manipulation: SocketSessionRecordManipulated) => {
      dispatch(actionSetSessionManipulationListener({ sessionManipulation: manipulation }));
    });

    newSocket.on(SocketChannels.CertificationProcessUpdate, payload => {
      dispatch(
        actionCertificationRoomUpdate({
          progress: {
            type: 'SUCCESS',
            result: payload,
          },
        }),
      );
    });

    if (currentUser) {
      coachSubscribeToAdHoc(currentUser.id);
    }

    setSocket(newSocket);
    setWs({
      socket: newSocket,
      joinRoom,
      getRoomStatus,
      sessionRecordManipulated,
      coachSubscribeToAdHoc,
      coachUpdateRating,
      emitDisconnect,
    });

    return () => {
      if (newSocket) {
        Object.values(SocketChannels).forEach(channel => {
          newSocket.removeAllListeners(channel);
        });
        newSocket.disconnect();
      }
    };
  }, [dispatch]);

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
};

export { WebSocketContext };
