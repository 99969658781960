import { INotifyOptions, Notify } from 'notiflix/build/notiflix-notify-aio';

interface AlertProps {
  width?: string;
  position?: string;
  distance?: string;
  opacity?: number;
  borderRadius?: string;
  timeout?: number;
  plainText?: boolean;
  clickToClose?: boolean;
  className?: string;
  zindex?: number;
  fontFamily?: string;
  fontSize?: string;
  closeButton?: boolean;
  useIcon?: boolean;
  html?: boolean;
  messageMaxLength?: number;
  pauseOnHover?: boolean;
}

const initAlertProps: AlertProps = {
  width: '280px',
  position: 'right-bottom',
  distance: '10px',
  opacity: 1,
  borderRadius: '5px',
  timeout: 3000,
  plainText: true,
  clickToClose: false,
  className: 'notiflix-notify',
  zindex: 4001,
  fontFamily: 'Quicksand',
  fontSize: '13px',
  closeButton: false,
  useIcon: false,
  pauseOnHover: false,
  messageMaxLength: 500,
};

class Alert {
  static init() {
    Notify.init(initAlertProps as INotifyOptions);
  }

  static success(text: string, options?: AlertProps) {
    Notify.success(text, { ...options, plainText: options && options.html ? !options.html : true } as INotifyOptions);
  }

  static error(text: string, options?: AlertProps) {
    Notify.failure(text, { ...options, plainText: options && options.html ? !options.html : true } as INotifyOptions);
  }

  static warning(text: string, options?: AlertProps) {
    Notify.warning(text, { ...options, plainText: options && options.html ? !options.html : true } as INotifyOptions);
  }
}

export default Alert;
