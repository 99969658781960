import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RecordStatus, SessionStatus, USER_ROLES } from '../../../common/enums';
import { ICurrentUser, WsType } from '../../../common/interfaces';
import AbstractModal from '../../../components/AbstractModal';
import InviteCoaches from '../../../components/InviteCoaches';
import ModalContent from '../../../components/ModalContent';
import RecordedOnList from '../../../components/RecordedOnList';
import DraggableModal from '../../../components/RecordPlayerModal/DraggableModal';
import RecordPlayer from '../../../components/RecordPlayerModal/RecordPlayer';
import ScoringMarksList from '../../../components/ScoringMarksList';
import TeachableMomentInput from '../../../components/TeachableMomentInput';
import TeachableMomentList from '../../../components/TeachableMomentList';
import { WebSocketContext } from '../../../context/socket';
import { setSessionScoreResult, setSessionStatus, setSessionTeachableMoments } from '../../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { CaseResponseDto } from '../../../service/dto/case.dto';
import { SessionResponseDto } from '../../../service/dto/session.dto';
import { SessionService } from '../../../service/session.service';
import Alert from '../../../utils/alert';
import { DurationMS, scoreColors, scoreI18NKey } from '../../../utils/constants';
import { retryOperationAsync } from '../../../utils/funcs';
import { SocketSessionRecordManipulated } from '../socket-session-record-manipulated.interface';
import PrimaryButton from './../../../components/PrimaryButton';
import SecondaryButton from './../../../components/SecondaryButton';
import FeedbackModal from './components/FeedbackModal';
import DailyWrapper from './DailyWrapper';

// This is main component, which is rendering video call and all related control elements
const VideoChat = ({
  caseData,
  currentUser,
  shareToken,
}: {
  caseData: CaseResponseDto;
  currentUser: ICurrentUser;
  shareToken: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const socket: WsType | null = useContext(WebSocketContext);

  useEffect(() => {
    console.log('socket in video chat', socket);
  }, [socket]);

  const [role, setRole] = useState<USER_ROLES>(USER_ROLES.SP);
  const [finishModal, setFinishModal] = useState<boolean>(false);

  // session selectors
  const session: SessionResponseDto = useAppSelector((state: any) => state.sessionConfig.session);
  const {
    id: sessionId,
    status: sessionStatus,
    dailyCoachingSessionRecord: coachingSessionRecord,
    dailyFeedbackSessionRecord: feedbackSessionRecord,
    scoreResult,
    teachableMoments,
    dailyRoomUrl,
    coach,
    trainee,
    caseTemplateSummary,
    feedbackVisibleToTrainee,
  } = session;
  const currentSessionRecord = useMemo(() => {
    return sessionStatus === SessionStatus.Coaching ? coachingSessionRecord : feedbackSessionRecord;
  }, [sessionStatus, coachingSessionRecord, feedbackSessionRecord]);

  // UI state
  const sessionManipulationState: SocketSessionRecordManipulated = useAppSelector<SocketSessionRecordManipulated>(
    (state: any) => state.socketManipulationConfig.sessionManipulation,
  );
  const [isCoachingSessionRecordVisible, setIsCoachingSessionRecordVisible] = useState<boolean>(false);
  const [clickedFeedback, setClickedFeedback] = useState<boolean>(false);
  const [teachableMomentText, setTeachableMomentText] = useState<string>('');
  const [sessionShareToken, setSessionShareToken] = useState<string>('');
  const [inviteCoach, setInviteCoach] = useState<boolean>(false);
  const [isRecordingRequestInProgress, setIsRecordingRequestInProgress] = useState<boolean>(false);
  const [videoRecordTitleConfig, setVideoRecordTitleConfig] = useState<{
    index: number;
    name: string;
    value: number;
  } | null>(null);
  const [dailyToken, setDailyToken] = useState<string>('');
  const [sessionRecordDownloadError, setSessionRecordDownloadError] = useState<string | undefined>(undefined);
  const [sessionRecordDownloadResponse, setSessionRecordDownloadResponse] = useState<{ url: string } | undefined>(
    undefined,
  );
  const [feedbackModalVisible_, setFeedbackModalVisible] = useState<boolean>(false);
  const [recordingDuration, setRecordingDuration] = useState<string>('');

  // computed values
  const feedbackModalVisible = useMemo(
    () => feedbackModalVisible_ && role === USER_ROLES.SP,
    [feedbackModalVisible_, role],
  );
  const sessionRecordInProgress = useMemo(() => currentSessionRecord?.recordingActive ?? false, [currentSessionRecord]);
  const displayFeedbackItems = useMemo(
    () => sessionStatus === SessionStatus.Feedback && (currentUser.id === coach?.id || feedbackVisibleToTrainee),
    [feedbackVisibleToTrainee, sessionStatus],
  );
  const displayFBToggleForCoach = sessionStatus === SessionStatus.Feedback && currentUser.id === coach?.id;

  const setSessionRecordManipulation = (manipulation: Partial<SocketSessionRecordManipulated>) => {
    const fullManipulation = {
      ...sessionManipulationState,
      ...manipulation,
      sessionId: sessionId,
    } as SocketSessionRecordManipulated;
    socket!.sessionRecordManipulated(fullManipulation);
  };

  const fetchDailyToken = useCallback(async () => {
    if (!sessionId) {
      return;
    }

    try {
      const tokenResponse = await SessionService.getToken(sessionId, shareToken);
      setDailyToken(tokenResponse.token);
    } catch (error) {
      console.error('Error fetching Daily token:', error);
      Alert.error(t('errorMessages.dailyTokenFetchFailed'));
    }
  }, [sessionId, shareToken, t]);

  useEffect(() => {
    const getMediaUrl = async () => {
      setIsCoachingSessionRecordVisible(sessionManipulationState.isVisible);
      if (!sessionRecordDownloadResponse) {
        const { url } = await SessionService.getCoachSessionRecordMedia(sessionId);
        setSessionRecordDownloadResponse({ url });
      }
    };

    if (sessionManipulationState.sessionId !== sessionId) {
      return;
    }

    getMediaUrl();
  }, [sessionManipulationState]);

  useEffect(() => {
    if (!sessionId || !currentUser || !socket) {
      return;
    }

    const determineRole = () => {
      if (shareToken) return USER_ROLES.Spectator;
      if (currentUser.id === coach?.id) return USER_ROLES.SP;
      if (currentUser.id === trainee?.id) return USER_ROLES.Trainee;
      return USER_ROLES.Spectator;
    };

    const initiateSockets = () => {
      if (!sessionId) return;
      socket!.joinRoom(sessionId, currentUser.id);
      socket!.getRoomStatus(sessionId, currentUser.id);
    };

    const process = async () => {
      setRole(determineRole());
      initiateSockets();
      await fetchDailyToken();
      if (currentUser.id === coach?.id) {
        SessionService.getShareToken(sessionId).then(setSessionShareToken).catch(console.error);
      }
    };

    process();
    return () => {
      socket?.emitDisconnect();
    };
  }, [sessionId, currentUser, socket]);

  useEffect(() => {
    const updateDuration = () => {
      if (!currentSessionRecord?.recordingActive) {
        setRecordingDuration('');
        return;
      }
      const startSeconds = Number(currentSessionRecord.recordStartTime);
      const nowSeconds = Math.floor(Date.now() / 1000);
      const durationInSeconds = nowSeconds - startSeconds;
      const minutes = Math.floor(durationInSeconds / 60);
      const remainingSeconds = durationInSeconds % 60;
      // Format duration as mm:ss
      const formattedString = `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
      setRecordingDuration(formattedString);
    };
    //initiaul update
    updateDuration();
    const intervalId = setInterval(() => {
      updateDuration();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [currentSessionRecord?.recordingActive, currentSessionRecord?.recordStartTime]);

  useEffect(() => {
    const fetchTeachableMoments = async () => {
      if (!sessionId) {
        return;
      }

      const teachableMoments = await SessionService.getTeachableMoments(sessionId);
      dispatch(setSessionTeachableMoments(teachableMoments));
    };

    switch (sessionStatus) {
      case SessionStatus.Feedback:
        fetchTeachableMoments();
        break;

      case SessionStatus.Finished:
        if (shareToken) {
          Alert.warning(t('stream.session_passed'));
          navigate('/dashboard');
          break;
        }

        navigate(`/session/result/${sessionId}`);
        break;
      default:
        break;
    }
  }, [sessionStatus]);

  const getRecordMediaUrl = useCallback(
    async (abortSignal?: AbortSignal) => {
      setSessionRecordDownloadError(undefined);
      try {
        const { url } = await SessionService.getCoachSessionRecordMedia(sessionId, abortSignal);
        setSessionRecordDownloadResponse({ url });
      } catch (error) {
        if (error instanceof Error && error.name === 'AbortError') {
          return;
        }

        console.error('Error fetching session record media:', error);
        setSessionRecordDownloadError(t('errorMessages.sessionRecordMediaFetchFailed'));
      }
    },
    [sessionId],
  );

  useEffect(() => {
    if (!coachingSessionRecord) {
      return;
    }

    if (sessionRecordDownloadResponse) {
      return;
    }

    if (coachingSessionRecord.status !== RecordStatus.Available) {
      return;
    }

    const abortController = new AbortController();
    getRecordMediaUrl(abortController.signal);
    return () => abortController.abort();
  }, [coachingSessionRecord]);

  const handleRecordButtonClick = useCallback(async () => {
    if (!sessionId) {
      return;
    }

    if (isRecordingRequestInProgress) {
      return;
    }

    try {
      setIsRecordingRequestInProgress(true);
      await SessionService.dailyToggleSessionRecord(sessionId);
      // setSessionRecordInProgress(!sessionRecordInProgress);
    } catch (error) {
      console.error('Error toggling session recording:', error);
      Alert.warning(t('errorMessages.dailyToggleSessionRecordFailed'));
    } finally {
      setIsRecordingRequestInProgress(false);
    }
  }, [sessionId, isRecordingRequestInProgress, t]);

  const serviceFeedbackStream = () => {
    if (!sessionId) {
      return;
    }

    const feedbackStreamPromise = () =>
      new Promise<any>((resolve, reject) => {
        SessionService.feedbackStream(
          sessionId,
          (data: any) => {
            if (data?.session) {
              setClickedFeedback(true);
            }
            resolve(data);
          },
          (error: any) => {
            reject(error);
          },
        );
      });

    return retryOperationAsync(feedbackStreamPromise, 1000, 10);
  };

  const serviceFinishStream = () => {
    if (!sessionId) {
      return;
    }

    SessionService.endStream(
      sessionId,
      (data: any) => {
        handleFinishSessionModal();
        dispatch(setSessionStatus(SessionStatus.Finished));
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  const handleFinishSessionModal = () => {
    setFinishModal(!finishModal);
  };

  const handleScore = (id: string, value: number) => {
    if (!sessionId) {
      return;
    }

    SessionService.score(
      sessionId,
      {
        id,
        value,
      },
      (data: SessionResponseDto) => {
        dispatch(setSessionScoreResult(data.scoreResult));
      },
      console.error,
    );
  };

  const handleToggleFeedbackVisibilityForTrainee = () => {
    if (!sessionId) {
      return;
    }
    SessionService.toggleFeedbackVisibilityForTrainee(
      sessionId,
      () => {},
      () => {
        Alert.error(t('errorMessages.somethingWentWrong'));
      },
    );
  };

  const handleTeachableInput = (value: string) => {
    setTeachableMomentText(value);
  };

  const noteTeachableMoment = () => {
    if (!sessionId || teachableMomentText.trim().length === 0) return;

    SessionService.note(
      sessionId,
      { text: teachableMomentText },
      data => {
        handleTeachableInput('');
        dispatch(setSessionTeachableMoments(data.teachableMoments ?? []));
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  const _getVideoRecordModalTitle = (): ReactNode => {
    return videoRecordTitleConfig?.index != null ? (
      <div className='boldTitle'>
        <span>{(videoRecordTitleConfig.index + 1).toString()}. </span>
        <span>{videoRecordTitleConfig.name}</span>
        <span> - </span>
        {t(scoreI18NKey.get(videoRecordTitleConfig.value)!) ? (
          <span style={{ color: scoreColors.get(videoRecordTitleConfig.value) }}>
            {t(scoreI18NKey.get(videoRecordTitleConfig.value)!)}
          </span>
        ) : (
          <span>{t('session.teachableMoment')}</span>
        )}
      </div>
    ) : (
      <div className='grayTitle'>{t('session.loading')}</div>
    );
  };

  const handleStartFeedbackModal = (confirmation: boolean) => {
    setFeedbackModalVisible(false);
    if (!confirmation) {
      return;
    }

    serviceFeedbackStream();
  };

  const allowDailyWrapper = useMemo(() => {
    return dailyRoomUrl && dailyToken && sessionStatus !== SessionStatus.Finished;
  }, [dailyRoomUrl, dailyToken, sessionStatus]);

  const memorizedDailyWrapper = useMemo(() => {
    if (!allowDailyWrapper) {
      return null;
    }

    return (
      <DailyWrapper
        roomUrl={dailyRoomUrl}
        token={dailyToken}
        userName={currentUser.firstName + ' ' + currentUser.lastName}
        userId={currentUser.id}
      />
    );
  }, [allowDailyWrapper]);
  if (!sessionId) {
    return null;
  }

  // Add useEffect for escape key handling
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      const isCoach = session.coach.id === currentUser.id;
      const isEscapeKey = event.key === 'Escape';
      const isFeedbackSession = sessionStatus === SessionStatus.Feedback;

      if (!isCoach || !isEscapeKey || !isFeedbackSession) {
        return;
      }

      setSessionRecordManipulation({
        isMuted: true,
        isPlaying: false,
        isVisible: false,
        timeInSeconds: 0,
      });
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => document.removeEventListener('keydown', handleEscapeKey);
  }, [session.coach.id, currentUser.id, sessionStatus]);

  const renderParticipant = (label: string, participant: { firstName: string; lastName: string }) => {
    return (
      <div className=''>
        <div className='inline-block'>
          <span className='text-gray-light font-bold'>{label}</span>
          <span className='text-gray-dark font-bold'>{` ${participant.firstName} ${participant.lastName}`}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='flex flex-row justify-between mb-4'>
        <div className='flex shrink-0'>
          <div className=''>
            <div className='font-bold text-lg text-black'>{caseTemplateSummary.name}</div>
            {role !== USER_ROLES.SP && coach && renderParticipant(t('session.stream.coachName'), coach)}
            {role !== USER_ROLES.Trainee && trainee && renderParticipant(t('session.stream.traineeName'), trainee)}
          </div>
        </div>

        <div className='flex justify-end text-right space-x-3'>
          <div className='border-r flex flex-col justify-center border-solid border-black-divider pr-3'>
            <div className='text-lg text-black font-bold'>
              {sessionStatus === SessionStatus.Coaching &&
                (clickedFeedback ? t('session.stream.waitingForFeedback') : t('session.stream.coachingSession'))}
              {sessionStatus === SessionStatus.Feedback && t('session.stream.feedbackSession')}
              {sessionStatus === SessionStatus.Finished && t('session.stream.finishedSession')}
              {currentSessionRecord?.recordingActive && currentSessionRecord?.recordStartTime && (
                <span className='text-negative'> {recordingDuration}</span>
              )}
            </div>
          </div>
          {role !== USER_ROLES.Spectator && (
            <div className={'flex flex-row justify-between items-center'}>
              {role === USER_ROLES.SP && (
                <SecondaryButton
                  className='text-negative border-negative hover:text-negative hover:bg-background-page h-12 mr-2 ml-2'
                  title={sessionRecordInProgress ? t('session.actions.stopRecord') : t('session.actions.startRecord')}
                  onClick={() => handleRecordButtonClick()}
                  disabled={isRecordingRequestInProgress}
                />
              )}
              {sessionStatus === SessionStatus.Coaching && role === USER_ROLES.SP && (
                <PrimaryButton
                  className='h-12'
                  title={t('session.stream.startFeedback')}
                  onClick={() => setFeedbackModalVisible(true)}
                />
              )}
              {sessionStatus !== SessionStatus.Finished && coach?.id === currentUser.id && sessionShareToken && (
                <SecondaryButton
                  className='text-primary border-primary hover:text-primary hover:bg-background-black h-12 mr-2 ml-2'
                  title={t('session.stream.invite')}
                  onClick={() => {
                    setInviteCoach(!inviteCoach);
                  }}
                />
              )}
              {sessionStatus === SessionStatus.Feedback &&
                coach.id === currentUser.id &&
                coachingSessionRecord?.status != RecordStatus.Aborted && (
                  <SecondaryButton
                    className='text-primary border-primary hover:text-primary hover:bg-background-black h-12  mr-2 ml-2'
                    title={
                      coachingSessionRecord && coachingSessionRecord.status === RecordStatus.Available
                        ? isCoachingSessionRecordVisible
                          ? t('session.recording.hideRecording')
                          : t('session.recording.showRecording')
                        : t('session.recording.prepareRecording')
                    }
                    onClick={() => {
                      const isVisible = !isCoachingSessionRecordVisible;
                      setIsCoachingSessionRecordVisible(isVisible);
                      setSessionRecordManipulation({
                        isMuted: true,
                        isPlaying: false,
                        timeInSeconds: 0,
                        isVisible: isVisible,
                      });
                    }}
                    disabled={!coachingSessionRecord || coachingSessionRecord.status !== RecordStatus.Available}
                  />
                )}
              {sessionStatus !== SessionStatus.Finished && (
                <SecondaryButton
                  className='text-negative border-negative hover:text-negative hover:bg-background-page h-12'
                  title={t('session.stream.endSession')}
                  icon='bi bi-x'
                  onClick={() => handleFinishSessionModal()}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <hr className='w-full h-px bg-black-divider border-0 my-4' />
      {sessionStatus !== SessionStatus.Finished && (
        <>
          <div className='grid grid-cols-12 w-full gap-6'>
            <div className='col-span-7 h-full min-h-[400px]'>
              {memorizedDailyWrapper}
              <div className='col-span-2 pt-6'>
                {role === USER_ROLES.SP &&
                  sessionStatus === SessionStatus.Coaching &&
                  scoreResult &&
                  Object.values(scoreResult).filter((el: any) => !!el.value).length > 0 && (
                    <>
                      <div className='text-black font-bold text-xl mb-4'>{t('session.scoreResults')}</div>
                      <RecordedOnList scoreResult={scoreResult} afterUpdateCallback={handleScore} />
                    </>
                  )}
                {role === USER_ROLES.SP &&
                  sessionStatus === SessionStatus.Coaching &&
                  teachableMoments &&
                  teachableMoments?.length > 0 && (
                    <>
                      <div className='text-black font-bold text-xl mb-4'>{t('session.teachableMoments')}</div>
                      <TeachableMomentList teachableMoments={teachableMoments} />
                    </>
                  )}
              </div>
            </div>
            {role === USER_ROLES.SP && sessionStatus === SessionStatus.Coaching && !inviteCoach && (
              <div className='col-span-5'>
                {caseTemplateSummary.allowTeachableMoments && (
                  <>
                    <div className='mb-2 text-lg text-primary-dark font-bold'>{t('session.teachableMoment')}</div>
                    <TeachableMomentInput
                      value={teachableMomentText}
                      handleInput={handleTeachableInput}
                      noteTeachableMoment={noteTeachableMoment}
                    />
                  </>
                )}
                {scoreResult && (
                  <ScoringMarksList caseData={caseData} scoreResult={scoreResult} handleScore={handleScore} />
                )}
              </div>
            )}
            {inviteCoach && (
              <div className='col-span-5'>
                <div className='mb-2 flex justify-between'>
                  <div className='text-lg text-primary-dark font-bold'>{t('session.stream.inviteUsers')}</div>
                  <div
                    className='text-mg text-primary font-bold flex cursor-pointer'
                    onClick={() => setInviteCoach(false)}>
                    <i className='bi bi-chevron-left mr-2' />
                    <p className='capitalize'>{t('session.stream.backToScoring')}</p>
                  </div>
                </div>
                <hr className='w-full h-px bg-black-divider border-0 mt-3 my-4' />
                <div className='mx-2'>
                  <InviteCoaches url={window.location.href + '/' + sessionShareToken} />
                </div>
              </div>
            )}
            {displayFeedbackItems && (
              <div className='mb-4 mt-6 col-span-5'>
                {displayFBToggleForCoach && (
                  <div
                    className={`flex mb-6 justify-between items-center rounded-lg p-4 ${
                      feedbackVisibleToTrainee ? 'bg-positive-lightest' : 'bg-warning-lightest'
                    }`}>
                    <div className='text-primary-darker'>
                      {t(
                        feedbackVisibleToTrainee
                          ? 'session.stream.infoFBvisibleToTrainee'
                          : 'session.stream.infoFBhiddenFromTrainee',
                      )}
                    </div>
                    <PrimaryButton
                      title={t(feedbackVisibleToTrainee ? 'buttons.hideFBFromTrainee' : 'buttons.displayFBToTrainee')}
                      className='!text-primary !bg-transparent !font-normal !px-0'
                      textTransform='capitalize'
                      onClick={handleToggleFeedbackVisibilityForTrainee}
                    />
                  </div>
                )}
                <div className={role == USER_ROLES.SP ? `grid grid-cols-1` : `2xl:grid 2xl:grid-cols-2 2xl:gap-4`}>
                  <RecordedOnList
                    editRatingsPermission={currentUser.isSuperAdmin || currentUser.id == coach.id}
                    sessionStatus={sessionStatus}
                    scoreResult={scoreResult}
                    isStar
                    recordStatus={coachingSessionRecord?.status}
                    isReplay={role == USER_ROLES.SP}
                    onClick={(ms: number) => {
                      const newTimeInSeconds = Math.max(0, Math.floor(ms / DurationMS.SEC));
                      setSessionRecordManipulation({
                        timeInSeconds: 0,
                        isMuted: sessionManipulationState?.isMuted ?? true,
                        isPlaying: false,
                      });

                      setTimeout(() => {
                        setSessionRecordManipulation({
                          timeInSeconds: newTimeInSeconds,
                          isMuted: sessionManipulationState?.isMuted ?? true,
                          isPlaying: false,
                        });

                        setIsCoachingSessionRecordVisible(true);
                        socket!.sessionRecordManipulated({
                          isMuted: sessionManipulationState?.isMuted ?? true,
                          isPlaying: false,
                          isVisible: true,
                          sessionId: sessionId,
                          timeInSeconds: newTimeInSeconds,
                        } as SocketSessionRecordManipulated);
                      });
                    }}
                  />
                </div>
                {teachableMoments && teachableMoments?.length > 0 && (
                  <div className='mb-4 mt-6'>
                    <TeachableMomentList isIcon teachableMoments={teachableMoments} />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {feedbackModalVisible && (
        <FeedbackModal
          handleClose={() => handleStartFeedbackModal(false)}
          handleConfirm={() => handleStartFeedbackModal(true)}
        />
      )}
      {finishModal && (
        <AbstractModal
          position={{ width: 'w-1/3', left: 'left-1/3' }}
          label={t('modals.endSession')}
          leftBtn={{
            label: t('buttons.noBackToSession'),
            onClick: () => {
              handleFinishSessionModal();
            },
          }}
          rightBtn={{
            label: t('buttons.yesEndSession'),
            onClick: () => {
              serviceFinishStream();
            },
          }}
          toDelete>
          <ModalContent
            entity='streamFinish'
            title={t('session.finishModal.title')}
            subtitle={t('session.finishModal.subtitle')}
          />
        </AbstractModal>
      )}
      {sessionStatus === SessionStatus.Feedback && isCoachingSessionRecordVisible && coachingSessionRecord && (
        <DraggableModal
          isCloseVisible={role === USER_ROLES.SP}
          title={_getVideoRecordModalTitle()}
          onCloseClick={() => {
            setSessionRecordManipulation({
              isMuted: true,
              isPlaying: false,
              isVisible: false,
              timeInSeconds: 0,
            });
          }}>
          <RecordPlayer
            scoreResult={scoreResult}
            teachableMoments={teachableMoments}
            record={coachingSessionRecord}
            error={sessionRecordDownloadError}
            download={sessionRecordDownloadResponse}
            isReadonly={role !== USER_ROLES.SP}
            watchManipulationInfo={sessionManipulationState}
            onManipulationCallback={newManipulationInfo => {
              if (role === USER_ROLES.SP) {
                setSessionRecordManipulation(newManipulationInfo);
              }
            }}
            isModal
          />
        </DraggableModal>
      )}
    </>
  );
};

export default VideoChat;
