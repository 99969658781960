import React, { useState } from 'react';
import { CoachSessionTab } from '../../service/dto/insights.dto';
import { ScoreItemDto, ScoreResultDto, SessionRecordResponseDto } from '../../service/dto/session.dto';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import AbstractModal from '../../components/AbstractModal';
import RecordPlayer from '../../components/RecordPlayerModal/RecordPlayer';
import InputLabel from '../../components/InputLabel';
import { getScoreValuesLength } from '../../utils';
import StaticRatingStar from '../../components/StaticRatingStar';

export interface EvaluationCoachTabsTabContent {
  title: string;
  averageScore?: number;
  averageEvaluationScore?: number;
  sessionTab?: CoachSessionTab[];
}

enum ModalState {
  Close,
  Coaching,
  Feedback,
  Scoring,
}

const EvaluationCoachTabs = ({ tabs }: { tabs: EvaluationCoachTabsTabContent[] }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [state, setState] = useState(ModalState.Close);
  const [scoringItem, setScoringItem] = useState<ScoreResultDto>();
  const [coachingSession, setCoachingSession] = useState<SessionRecordResponseDto>();
  const [feedbackSession, setFeedbackSession] = useState<SessionRecordResponseDto>();
  const { t } = useTranslation();

  return (
    <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
      <TabList className='flex justify-start space-x-8 text-black border-b border-separate text-xl select-none font-bold pl-7 mt-6'>
        {tabs.map(({ title }, index) => {
          return (
            <Tab className={`cursor-pointer ${selectedTabIndex == index ? 'text-primary' : ''}`} key={title + index}>
              <div>{title}</div>
              <div className={'h-4'} />
              <div className={`h-1 w-full bg-primary rounded-full ${selectedTabIndex == index ? '' : 'opacity-0'}`} />
            </Tab>
          );
        })}
      </TabList>
      {tabs.map(({ averageScore, averageEvaluationScore, sessionTab }, index) => {
        return (
          <TabPanel key={`evaluation-tab-panes-${index}`}>
            {index == 0 && (
              <div className='bg-ghostwhite m-5 py-2 mt-0'>
                <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
                  <i className='bi bi-person-lines-fill text-2xl py-3 pl-3' />
                  <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.evaluation.averageScore')}</p>
                  <p className='font-bold py-4 w-1/5 '>
                    {averageScore != 0 && averageScore != undefined ? Math.round(averageScore * 100) + '%' : 'n/A'}
                  </p>
                </div>
                <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
                  <i className='bi bi-check-lg text-2xl py-3 pl-3' />
                  <p className='font-bold py-4 w-1/5 flex-auto pl-5'>
                    {t('insights.evaluation.averageEvaluationScore')}
                  </p>
                  <p className='font-bold py-4 w-1/5 '>
                    {averageEvaluationScore != 0 && averageEvaluationScore != undefined
                      ? Math.round(averageEvaluationScore * 10) + '%'
                      : 'n/A'}
                  </p>
                </div>
              </div>
            )}
            {sessionTab && (
              <table className='dataTable table-fixed w-full bg-white px-4'>
                <thead>
                  <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
                    <td className='text-gray-dark text-lg font-bold py-4 text-center'>{t('table.trainee')}</td>
                    <td className='text-gray-dark text-lg font-bold py-4 text-center'>{t('table.case')}</td>
                    <td className='text-gray-dark text-lg font-bold py-4 text-center'>{t('session.startDate')}</td>
                    <td className='text-gray-dark text-lg font-bold py-4 w-1/12 text-center'>{t('table.score')}</td>
                    <td className='text-gray-dark text-lg font-bold py-4 w-1/9 text-center'>
                      {t('insights.evaluation.evaluationScore')}
                    </td>
                    <td className='text-gray-dark text-lg font-bold py-4 text-center'>{t('table.actions')}</td>
                  </tr>
                </thead>
                <tbody>
                  {sessionTab && sessionTab.length == 0 && (
                    <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
                      <td className='text-gray-dark text-lg font-bold py-4 text-center'>n/A</td>
                      <td className='text-gray-dark text-lg font-bold py-4 text-center'>n/A</td>
                      <td className='text-gray-dark text-lg font-bold py-4 text-center'>n/A</td>
                      <td className='text-gray-dark text-lg font-bold py-4 w-1/12 text-center'>n/A</td>
                      <td className='text-gray-dark text-lg font-bold py-4 w-1/9 text-center'>n/A</td>
                      <td className='text-gray-dark text-lg font-bold py-4 text-center'>n/A</td>
                    </tr>
                  )}
                  {sessionTab.map((value, indexDetails) => (
                    <tr className='border border-solid border-black-divider' key={indexDetails}>
                      <td className='text-gray-dark py-4 text-lg text-center'>
                        {value.traineeFirstName + ' ' + value.traineeLastName}
                      </td>
                      <td className='text-gray-dark py-4 text-lg text-center'>{value.caseName}</td>
                      <td className='text-gray-dark py-4 text-lg text-center'>{value.sessionDate}</td>
                      <td className='text-gray-dark py-4 text-lg text-center'>
                        {value.scoreOverall != 0 ? Math.round(value.scoreOverall * 100) + '%' : 'n/A'}
                      </td>
                      <td className='text-gray-dark py-4 text-lg text-center'>
                        {value.evaluationScore != null ? Math.round(value.evaluationScore * 10) + '%' : 'n/A'}
                      </td>
                      <td>
                        <span className='text-primary cursor-pointer pr-4'>
                          <p
                            className='bi bi-play-circle-fill text-primary-hover pt-5 text-lg'
                            onClick={() => {
                              setScoringItem(value.scoreResult);
                              setCoachingSession(value.coachingSessionRecord);
                              setState(ModalState.Coaching);
                            }}
                          >
                            {' '}
                            {t('result.tabs.coaching')}
                          </p>
                          <p
                            className='bi bi-play-circle-fill text-primary-hover pt-2 text-lg'
                            onClick={() => {
                              setFeedbackSession(value.feedbackSessionRecord);
                              setState(ModalState.Feedback);
                            }}
                          >
                            {' '}
                            {t('result.tabs.feedback')}
                          </p>
                          <p
                            className='bi bi-star-fill text-primary-hover pt-2 text-lg'
                            onClick={() => {
                              setScoringItem(value.scoreResult);
                              setState(ModalState.Scoring);
                            }}
                          >
                            {' '}
                            {t('insights.evaluation.scoreDetails')}
                          </p>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {state == ModalState.Coaching && (
              <AbstractModal
                position={{ width: 'w-1/2', left: 'left-1/3' }}
                label={t('result.tabs.coaching')}
                cancelButton={{
                  onClick: () => {
                    setState(ModalState.Close);
                  },
                }}
              >
                {coachingSession && (
                  <RecordPlayer
                    record={coachingSession}
                    isReadonly={false}
                    download={{ url: coachingSession.mediaUri ?? '' }}
                    scoreResult={scoringItem}
                  />
                )}
              </AbstractModal>
            )}
            {state == ModalState.Feedback && (
              <AbstractModal
                position={{ width: 'w-1/2', left: 'left-1/3' }}
                label={t('result.tabs.feedback')}
                cancelButton={{
                  onClick: () => {
                    setState(ModalState.Close);
                  },
                }}
              >
                {feedbackSession && (
                  <RecordPlayer
                    record={feedbackSession}
                    isReadonly={false}
                    download={{ url: feedbackSession.mediaUri ?? '' }}
                    scoreResult={scoringItem}
                  />
                )}
              </AbstractModal>
            )}
            {state == ModalState.Scoring && (
              <AbstractModal
                position={{ width: 'w-1/2', left: 'left-1/3' }}
                label={t('table.scoringItem')}
                cancelButton={{
                  onClick: () => {
                    setState(ModalState.Close);
                  },
                }}
              >
                {scoringItem && (
                  <div className='my-2 fs-5'>
                    <InputLabel
                      label={'Scoring Items'}
                      additionalLabel={`${getScoreValuesLength(scoringItem) ?? 0}/${
                        Object.values(scoringItem).length ?? 0
                      }`}
                      otherMB='mb-4'
                    />
                  </div>
                )}
                <div className='overflow-y-auto max-h-96'>
                  {scoringItem &&
                    Object.values(scoringItem)
                      .filter(el => !!el.value)
                      .map((item: ScoreItemDto, j: number) => (
                        <div key={item.name + j}>
                          <div className='flex'>
                            <div className='mr-6'>
                              <StaticRatingStar rate={item.value} tmstmp={item.tmstmp} />
                            </div>
                            <div className='w-full'>
                              <div className='flex justify-between'>
                                <div className='mb-2 leading-4 text-2xl pt-4'>
                                  <strong>{j + 1}.</strong> {item.name}
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr className='w-full h-px bg-black-divider border-0 mt-3 mb-4' />
                        </div>
                      ))}
                </div>
              </AbstractModal>
            )}
          </TabPanel>
        );
      })}
    </Tabs>
  );
};

export default EvaluationCoachTabs;
