import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AbstractModal from "../../../../components/AbstractModal";
import ModalContent from "../../../../components/ModalContent";
import { useAppSelector } from "../../../../redux/store";

export type FeedbackModalProps = {
    handleClose: () => void;
    handleConfirm: () => void;
}

const FeedbackModal = ({ handleClose, handleConfirm }: FeedbackModalProps) => {
  const { t } = useTranslation();   
  const coachingSessionRecordActive = useAppSelector((state: any) => state.socketRecordConfig.sessionRecord?.recordingActive ?? false);

  return (
    <AbstractModal
    position={{ width: 'w-1/3', left: 'left-1/3' }}
    label={t('modals.endCoaching')}
    leftBtn={{
      label: t('buttons.cancel'),
      onClick: handleClose,
    }}
    rightBtn={{
      label: t('buttons.ok'),
      onClick: handleConfirm,
    }}
    toDelete>
    <ModalContent
      entity='coachingFinish'
      title={t('session.coachingModal.title')}
      subtitle={coachingSessionRecordActive ? t('session.coachingModal.subtitleRecordActive') : t('session.coachingModal.subtitle')}
    />
  </AbstractModal>
  )
};

export default FeedbackModal;

