import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useNavigate, useParams } from 'react-router-dom';
import { userPageSize } from '../../common/constants';
import { COMPONENT_STATE, SessionStatus, SessionType, USER_ROLES } from '../../common/enums';
import { ICurrentInstitution, WsType } from '../../common/interfaces';
import AbstractModal from '../../components/AbstractModal';
import InputLabel from '../../components/InputLabel';
import PrimaryButton from '../../components/PrimaryButton';
import SearchField from '../../components/SearchField';
import { WebSocketContext } from '../../context/socket';
import { actionToggleInstitution } from '../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { IRouteComponent } from '../../routes';
import { CaseService } from '../../service/case.service';
import { CaseResponseDto } from '../../service/dto/case.dto';
import { ScoringResponseDto } from '../../service/dto/scoring.dto';
import { SessionInstitutionResponseDto, SessionResponseDto } from '../../service/dto/session.dto';
import { UserEditListDto, UserResponseDto } from '../../service/dto/user.dto';
import { SessionService } from '../../service/session.service';
import { UserService } from '../../service/user.service';
import { getDateAndTimeDuration, toDHMFormat, toHMSFormat } from '../../utils';
import Alert from '../../utils/alert';
import { DurationMS } from '../../utils/constants';
import { chooseInstitution, isInstitutionAdmin, setCurrentUserInstitution } from '../../utils/institution';
import UserTable from '../user/UserTable';
import BookSessionContent from './BookSessionContent';
import TimerComponent from './TimerComponent';

// This is view, when user looking on invitation to join session view
const SessionView = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  let state: COMPONENT_STATE = useAppSelector((state: any) => state.socketComponentConfig).componentState;
  const socket: WsType | null = useContext(WebSocketContext);

  const [book, setBook] = useState<boolean>(false);
  const [assignTraineeView, setAssignTraineeView] = useState<boolean>(false);
  const [roleInSession, setRoleInSession] = useState<USER_ROLES>(USER_ROLES.Trainee);
  const [isUserIAdmin, setIsUserIAdmin] = useState<boolean>(false);
  const [list, setList] = useState<UserEditListDto[]>([]);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const [session, setSession] = useState<SessionResponseDto>();
  const [caseData, setCaseData] = useState<CaseResponseDto>();

  const [[days, hrs, mins, secs], setTime] = React.useState([0, 0, 0, 0]);
  const [startDateInMS, setStartDateInMS] = useState<number>(Date.now());
  const dispatch = useAppDispatch();

  // TIMER
  const tick = () => {
    if (days === 0 && hrs === 0 && mins === 0 && secs === 0) {
    } else if (mins === 0 && secs === 0) {
      setTime([days, hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([days, hrs, mins - 1, 59]);
    } else {
      setTime([days, hrs, mins, secs - 1]);
    }
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  const setInitTimer = (startDate: any) => {
    let d1 = new Date();
    let d2 = new Date(startDate);
    var diffMF = d2.getTime() - d1.getTime();

    const days = Math.floor(diffMF / DurationMS.DAY);
    const hoursLeftMS = diffMF - DurationMS.DAY * days;
    const hours = Math.floor(hoursLeftMS / DurationMS.HOUR);
    const minLeftMS = hoursLeftMS - hours * DurationMS.HOUR;
    const mins = Math.floor(minLeftMS / DurationMS.MIN);
    const secLeftMS = minLeftMS - mins * DurationMS.MIN;
    const secs = Math.floor(secLeftMS / DurationMS.SEC);

    setTime([days, hours, mins, secs]);
  };

  const serviceDataUsers = () => {
    if (!isInstitutionAdmin(currentUser, currentInstitution)) {
      return;
    }

    UserService.list(
      { search: search, page: page, pageSize: userPageSize, rolesFilter: [USER_ROLES.Trainee] },
      (data: Array<UserResponseDto>) => {
        const newData: UserEditListDto[] = data.map(d => {
          return {
            id: d.id,
            firstName: d.firstName,
            lastName: d.lastName,
            email: d.email,
            roles: d.institutions.filter(i => i.institutionId === currentInstitution.id)[0].roles,
            isSuperAdmin: d.isSuperAdmin,
          };
        });
        setList(newData.filter(user => user.id !== session?.coach.id));
      },
      error => {
        setList([]);
      },
    );
  };

  const serviceCountFetch = () => {
    if (!isInstitutionAdmin(currentUser, currentInstitution)) {
      return;
    }

    UserService.count(
      { search: search },
      (data: number) => {
        setTotalUsers(data);
      },
      error => {
        setTotalUsers(0);
        setPage(0);
      },
    );
  };

  useEffect(() => {
    setPage(0);
    serviceCountFetch();
    serviceDataUsers();
  }, [search]);

  useEffect(() => {
    serviceDataUsers();
  }, [page]);

  useEffect(() => {
    if (session) {
      serviceDataUsers();
    }
  }, [session]);

  const onPageChange = (event: { selected: number }) => {
    setPage(event.selected);
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };

  // SERVICE
  const serviceFetchSession = (sessionId: string) => {
    SessionService.getById(
      sessionId,
      (data: SessionInstitutionResponseDto) => {
        if (!currentUser.institutions.find(institution => institution.institutionId === data.institution.id)) {
          Alert.error(t('errorMessages.sessionBelongToNotAllowedInstitution'));
          navigate('/schedule');
          return;
        }
        chooseInstitution(
          data.institution,
          () => {
            const institution: ICurrentInstitution = {
              id: data.institution.id,
              name: data.institution.name,
              code: data.institution.code,
            };

            dispatch(actionToggleInstitution(institution));
          },
          () => {
            Alert.error(t('errorMessages.switchInstitution'));
          },
        );
        setLoading(false);
        setSession(data);
        setStartDateInMS(new Date(data.startDate).getTime());
        const role = data.coach.id === currentUser.id ? USER_ROLES.SP : USER_ROLES.Trainee;
        setRoleInSession(role);
        // Load Case Template by Id
        caseServiceDataFetch(data.caseTemplateSummary.id);
        setInitTimer(data.startDate);
        if (data.type === SessionType.AdHocSession) {
          if (role === USER_ROLES.SP || data.status === SessionStatus.Scheduled) {
            socket!.joinRoom(data.id, currentUser.id);
          }
          openAdHocStream();
        }
      },
      error => {
        setLoading(false);
      },
    );
  };

  const caseServiceDataFetch = (chosenCaseId: string) => {
    CaseService.getById(
      chosenCaseId,
      (data: CaseResponseDto) => {
        setLoading(false);
        setCaseData(data);
      },
      error => {
        setLoading(false);
      },
    );
  };

  const serviceBook = () => {
    SessionService.book(
      session!.id,
      (data: SessionResponseDto) => {
        setSession(data);
        if (data.type === SessionType.RegularSession) {
          handleModalBook();
        }
        if (data.type === SessionType.AdHocSession) {
          socket!.joinRoom(data.id, currentUser.id);
        }
        Alert.success(t('successMessages.sessionBooked'));
      },
      error => {
        Alert.warning(t('errorMessages.sessionNotBooked'));
      },
    );
  };

  // Fetch
  useEffect(() => {
    serviceFetchSession(id!);
    const currentUserInstitution = setCurrentUserInstitution({
      currentUser,
      currentInstitution,
    });

    if (currentUserInstitution) {
      setIsUserIAdmin(currentUserInstitution.roles.includes(USER_ROLES.Admin));
    }
    return () => {
      socket?.emitDisconnect();
    };
  }, [loading]);

  useEffect(() => {
    if (!session || session.status !== SessionStatus.Scheduled) {
      serviceFetchSession(id!);
    } else {
      openAdHocStream();
    }
  }, [state]);

  const openAdHocStream = () => {
    if (
      session?.type === SessionType.AdHocSession &&
      session.status === SessionStatus.Scheduled &&
      state === COMPONENT_STATE.STREAM
    ) {
      openStream();
    }
  };

  const openStream = () => {
    if (session) {
      if (process.env.notOpenSessionAsNewTab) {
        navigate('/session/stream/' + session.id);
      } else {
        window.open('#/session/stream/' + session.id, '_blank');
      }
    }
  };

  const getInfoText = (): string => {
    return session?.status === SessionStatus.Open
      ? timeExpired
        ? t('session.infoText.passed')
        : timeLessThan2hrsBeforeStart && roleInSession === USER_ROLES.Trainee
        ? t('session.infoText.bookingExpired')
        : t('session.infoText.startsIn')
      : sessionFinished
      ? t('session.infoText.passed')
      : t('session.infoText.startsIn');
  };

  const getTimerContent = (): string => {
    return timeExpired
      ? t('session.timerView.0')
      : timeLessThan5minBeforeStart
      ? toHMSFormat(hrs, mins, secs)
      : toDHMFormat(days, hrs, mins);
  };

  const getTimerView = (): string => {
    return timeLessThan5minBeforeStart ? t('session.timerView.hms') : t('session.timerView.dhm');
  };

  const getButtonTitle = (): string => {
    return session?.status === SessionStatus.Open && roleInSession === USER_ROLES.Trainee
      ? t('buttons.bookSession')
      : t('buttons.startSession');
  };

  const getButtonDisabled = (): boolean => {
    if (session) {
      if (
        session.status === SessionStatus.Finished ||
        (session.status === SessionStatus.Open && roleInSession === USER_ROLES.SP) ||
        (session.status === SessionStatus.Scheduled && session.type === SessionType.AdHocSession)
      ) {
        return true;
      }
      if (
        session.status === SessionStatus.Scheduled ||
        session.status === SessionStatus.Coaching ||
        session.status === SessionStatus.Feedback
      ) {
        const coachId = session.coach.id;
        const traineeId = session.trainee.id;
        if (currentUser.id !== coachId && currentUser.id !== traineeId) {
          return true;
        }
      }
    }
    return false;
  };

  const getButtonAction = () => {
    return session?.status === SessionStatus.Open && roleInSession === USER_ROLES.Trainee
      ? handleModalBook
      : openStream;
  };

  const handleModalBook = () => {
    if (session?.type === SessionType.RegularSession) {
      setBook(!book);
    } else {
      serviceBook();
    }
  };

  const handleBookSessionSubmit = () => {
    serviceBook();
  };

  const assignTrainee = (user: UserEditListDto) => {
    if (session) {
      SessionService.assignTrainee(
        { sessionId: session.id, traineeId: user.id },
        data => {
          setSession(data);
          Alert.success(t('successMessages.traineeAssignedSuccessfully'));
        },
        err => {
          console.log(err);
        },
      );
    }
  };

  const renderTime = (item: SessionResponseDto) => {
    const { startTime, endTime, monthLabel, day } = getDateAndTimeDuration(item);
    return `${monthLabel} ${day}, ${startTime} - ${endTime}`;
  };

  const timeMoreThan5minBeforeStart = Date.now() < startDateInMS - 5 * DurationMS.MIN;
  const timeLessThan5minBeforeStart = Date.now() > startDateInMS - 5 * DurationMS.MIN && Date.now() < startDateInMS;
  const timeLessThan2hrsBeforeStart = Date.now() > startDateInMS - 2 * DurationMS.HOUR && Date.now() < startDateInMS;
  const timeExpired = Date.now() > startDateInMS;
  const sessionFinished = Date.now() > 2 * DurationMS.HOUR + startDateInMS;

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      <div className='flex justify-between'>
        <div className='w-4/6 lg:w-1/2'>
          {session && caseData && (
            <div className='grid grid-cols-1'>
              <div>
                <InputLabel label={t('inputLabels.sessionCase')} />
                <p className='font-bold text-lg text-black'>{caseData.name}</p>
                <hr className='w-full h-px bg-black-divider border-0 my-6'></hr>
                <InputLabel label={t('inputLabels.timeAndDate')} />
                <p className='font-bold text-lg text-black'>{renderTime(session)}</p>
                <hr className='w-full h-px bg-black-divider border-0 my-6'></hr>
              </div>
              <div>
                <InputLabel label={t('inputLabels.sessionCoach')} />
                <p className='text-black text-lg mb-6'>{session.coach.firstName + ' ' + session.coach.lastName}</p>
                {session.trainee && (
                  <>
                    <InputLabel label={t('inputLabels.sessionTrainee')} />
                    <p className='text-black text-lg mb-6'>
                      {session.trainee.firstName + ' ' + session.trainee.lastName}
                    </p>
                  </>
                )}
                <InputLabel label={t('inputLabels.sessionLength')} />
                <p className='text-black text-lg mb-6'>
                  {t('inputLabels.interview')} {caseData.interview_duration} {t('table.min')}.{' '}
                  {t('inputLabels.feedback')} {caseData.feedback_duration} {t('table.min')}.
                </p>

                <InputLabel label={t('inputLabels.description')} />
                <p className='text-black text-lg mb-6'>{caseData.description}</p>

                {roleInSession === USER_ROLES.SP && (
                  <>
                    <InputLabel label={t('inputLabels.scoringItems')} />
                    <ul className='mx-0 mt-0 mb-6'>
                      {caseData.scoring_items.map((scoringItem: ScoringResponseDto) => (
                        <li className='text-black text-lg' key={'k_' + scoringItem.id}>
                          {scoringItem.name}
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                <InputLabel label={t('inputLabels.instructions')} />
                <p className='text-black text-lg'>{caseData.instructions}</p>
                <hr className='w-full h-px bg-black-divider border-0 my-6'></hr>
              </div>
            </div>
          )}
        </div>

        <div className='w-2/6 lg:w-3/12'>
          {session?.type === SessionType.RegularSession ? (
            <TimerComponent
              infoText={getInfoText()}
              buttonTitle={getButtonTitle()}
              timerView={getTimerView()}
              timerContent={getTimerContent()}
              disableButton={getButtonDisabled()}
              handleWidget={getButtonAction()}
              showSecondButton={isUserIAdmin && session && !session.trainee}
              secondButtonAction={() => {
                setAssignTraineeView(true);
              }}
              secondButtonTitle={t('buttons.showAssignTraineeModal')}
            />
          ) : (
            <div className='text-center bg-white py-6 px-2 rounded-lg min-h-4.5'>
              {session?.type === SessionType.AdHocSession &&
              roleInSession === USER_ROLES.SP &&
              (session.status === SessionStatus.Open || session.status === SessionStatus.Scheduled) ? (
                <>
                  <div className='mx-4 mb-6 font-bold'>{t('session.infoText.pleaseWait')}</div>
                  <div className='mx-4 mb-6'>{t('session.infoText.waitForOtherParticipant')}</div>
                </>
              ) : (
                <PrimaryButton
                  title={t('buttons.joinSession')}
                  onClick={() => {
                    getButtonAction()();
                  }}
                  disabled={getButtonDisabled()}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {book && (
        <AbstractModal
          label={session?.caseTemplateSummary.name}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              handleModalBook();
            },
          }}
          rightBtn={{
            label: t('buttons.bookThisSession'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleBookSessionSubmit();
            },
          }}
          cancelButton={{
            onClick: () => {
              handleModalBook();
            },
          }}>
          <BookSessionContent session={session!} caseData={caseData} />
        </AbstractModal>
      )}
      {assignTraineeView && (
        <AbstractModal
          label={t('modals.assignTrainee')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setAssignTraineeView(false);
            },
          }}
          cancelButton={{
            onClick: () => {
              setAssignTraineeView(false);
            },
          }}>
          <div>
            <div className='p-6'>
              <div className='mr-6'>
                <SearchField onSearch={onSearch} />
              </div>
            </div>
            <div>
              <UserTable
                list={list}
                showRoles={false}
                actions={[
                  {
                    action: (index: number) => {
                      setAssignTraineeView(false);
                      assignTrainee(list[index]);
                    },
                    buttonTitle: t('buttons.select'),
                  },
                ]}></UserTable>
              {!!totalUsers && (
                <ReactPaginate
                  previousLabel={<i className='bi bi-chevron-left' />}
                  breakLabel='...'
                  nextLabel={<i className='bi bi-chevron-right' />}
                  onPageChange={onPageChange}
                  pageRangeDisplayed={5}
                  pageCount={Math.floor((totalUsers - 1) / userPageSize) + 1}
                  containerClassName='pagination flex justify-center text-gray-dark'
                  pageClassName='page-item'
                  pageLinkClassName='page-link'
                  previousClassName='page-item'
                  previousLinkClassName='page-link'
                  nextClassName='page-item'
                  nextLinkClassName='page-link'
                  activeClassName='active'
                  breakClassName='page-item'
                  breakLinkClassName='page-link'
                  forcePage={page}
                />
              )}
            </div>
          </div>
        </AbstractModal>
      )}
    </div>
  );
};

export default SessionView;
