import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAppSelector} from '../../../redux/store';
import {NavLink} from 'react-router-dom';
import NavTab from '../../../components/NavTab';
import Switcher from './Switcher';
import {setCurrentUserInstitution} from '../../../utils/institution';
import {USER_ROLES, USER_STATUS} from '../../../common/enums';
import {ICurrentInstitution, ICurrentUser} from '../../../common/interfaces';
import {arrayIncludeArray} from '../../../utils';
import logo from '../../../assets/icons/logo.svg';

interface INavItem {
  title: string;
  link: string;
  icon: string;
  roles: USER_ROLES[];
}

const menuLinks = [
  {
    title: 'sidebar.users',
    link: '/users',
    icon: 'bi bi-person-lines-fill',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.scoringItems',
    link: '/scoring',
    icon: 'bi bi-list-ol',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.cases',
    link: '/cases',
    icon: 'bi bi-inboxes-fill',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.category',
    link: '/category',
    icon: 'bi bi-grid-fill',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.schedule',
    link: '/schedule',
    icon: 'bi bi-calendar-week-fill',
    roles: [USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    title: 'sidebar.liveSessions',
    link: '/live-sessions',
    icon: 'bi bi-calendar-week-fill',
    roles: [USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  // TODO: brig back certification!
  {
    title: 'sidebar.history',
    link: '/history',
    icon: 'bi bi-clock-history',
    roles: [USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee],
  },
  {
    title: 'sidebar.sessionLog',
    link: '/log',
    icon: 'bi bi-calendar-minus-fill',
    roles: [USER_ROLES.Admin],
  },
];

const administrationLinks = [
  {
    title: 'Institutions',
    link: '/institutions',
    icon: 'bi bi-briefcase-fill',
    roles: [],
  },
];

const insightsMenu = [
  {
    title: 'sidebar.usage',
    link: '/usage',
    icon: 'bi bi-bricks',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.casesInsights',
    link: '/insights',
    icon: 'bi bi-card-list',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.evaluation',
    link: '/evaluation',
    icon: 'bi bi-clipboard-data',
    roles: [USER_ROLES.Admin],
  },
  {
    title: 'sidebar.reporting',
    link: '/reporting',
    icon: 'bi bi-clipboard2-check',
    roles: [USER_ROLES.Admin],
  }
];

const Sidebar: React.FC = () => {
  const { t } = useTranslation();

  const { user: currentUser } = useAppSelector((state: any) => state.auth);
  const currentInstitution = useAppSelector((state: any) => state.institution);

  const isUserInstitutionAdmin = () => {
    const _user = currentUser as ICurrentUser;
    const _institution = currentInstitution as ICurrentInstitution;
    if (!_user || !_institution) {
      return false;
    }
    const index = _user.institutions.findIndex(item => item.institutionId == _institution.id);

    if (index == -1) {
      return false;
    }
    const institutionRelation = _user.institutions[index]!;
    if (institutionRelation.status != USER_STATUS.Active) {
      return false;
    }

    return institutionRelation.roles.indexOf(USER_ROLES.Admin) != -1;
  };
  const renderNav = (title: string, currentUser: ICurrentUser, links: INavItem[]) => {
    const currentUserInstitution = setCurrentUserInstitution({
      currentUser,
      currentInstitution,
    });

    if (!currentUserInstitution) {
      return;
    }

    return (
      <div className='mb-12'>
        <div className='uppercase text-sm text-gray-dark font-bold'>{title}</div>
        {links
          .map((item, j) => {
            // MOVE TO USER
            if (arrayIncludeArray(currentUserInstitution.roles, item.roles) || currentUser.isSuperAdmin)
              return <NavTab {...item} key={'navItem_' + j} />;
          })
          .filter(i => i)}
      </div>
    );
  };

  return (
    <div className='bg-white border border-solid border-black-divider flex-2'>
      <div className='p-6 h-22'>
        <NavLink to={'/dashboard'}>
          <img src={logo} alt='logo' className='inline' />
        </NavLink>
      </div>

      <div className='bg-primary-lightest pt-3 pb-6'>
        <div className='px-6 uppercase text-sm text-gray-dark font-bold'>{t('layout.institution')}</div>
        <Switcher />
      </div>

      <div className='px-6 pt-6'>
        {renderNav(t('layout.menu'), currentUser, menuLinks)}

        {currentUser.isSuperAdmin == true && renderNav(t('layout.administration'), currentUser, administrationLinks)}
        {isUserInstitutionAdmin() && renderNav(t('layout.insightsMenu'), currentUser, insightsMenu)}
      </div>
    </div>
  );
};

export default Sidebar;
