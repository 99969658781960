import React, { useEffect, useState } from 'react';
import {
  ScoreResultDto,
  SessionRecordResponseDto,
  SessionResponseDto,
  TeachableMomentDto,
} from '../../../service/dto/session.dto';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import RecordPlayer from '../../../components/RecordPlayerModal/RecordPlayer';
import { SessionEvaluationState, SessionResultState } from '../../../redux/types';
import SessionEvaluate from '../SessionEvaluate';
import { SessionEvaluationResponse } from '../../../service/dto/session-evaluate.dto';
import { useAppSelector } from '../../../redux/store';
import { SessionService } from '../../../service/session.service';
import { RecordStatus } from '../../../common/enums';

export enum RecordTabType {
  RecordType = 'record-tab-type:record',
  SessionEvaluate = 'record-tab-type:session-evaluate',
  OtherType = 'record-tab-type:other',
}

export interface TabContent {
  title: string;
  type: RecordTabType;
  data: RecordTabContentData | SessionEvaluationTabContentData;
}

export interface RecordTabContentData {
  isReadonly: boolean;
  record?: SessionRecordResponseDto;
  score?: ScoreResultDto;
  teachableMoments?: TeachableMomentDto[];
  error?: string;
  type: string;
}

export interface SessionEvaluationTabContentData {
  isCoach: boolean;
  session: SessionResponseDto;
  sessionEvaluate: SessionEvaluationResponse;
}

const MemoizedSessionEvaluateTab = React.memo(({ sessionEvaluateData }: {
  sessionEvaluateData: SessionEvaluationTabContentData;
}) => {
  if (!sessionEvaluateData.session) {
    return <div>No session</div>;
  }

  return (
    <SessionEvaluate
      isCoach={sessionEvaluateData.isCoach}
      session={sessionEvaluateData.session}
      sessionEvaluate={sessionEvaluateData.sessionEvaluate}
    />
  );
});

const RecordsTabs = ({ tabs, onTabSelected, sessionId }: { tabs: TabContent[]; onTabSelected?: Function, sessionId: string }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [lastTimeManipulation, setLastTimeManipulation] = useState<number | undefined>();
  const [playing, setPlaying] = useState<boolean | undefined>();
  const [unMute, setUnMuting] = useState<boolean | undefined>();
  const sessionResult = useAppSelector((state: any) => state.sessionResultReducer) as SessionResultState;
  const sessionEvaluateState: SessionEvaluationState = useAppSelector((state: any) => state.sessionEvaluationReducer);
  const [mediaUrlToRecord, setMediaUrlToRecord] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const MAX_RETRY_COUNT = 20;
    const POLLING_INTERVAL = 3000;

    const fetchMediaUrl = async (call: Function, recordType: string, retryCount = 0) => {
      try {
        const response = await call(sessionId);
        const mediaUrl = response.url;
        setMediaUrlToRecord(prev => ({ ...prev, [recordType]: mediaUrl }));
      } catch (error: any) {
        if (error?.response?.status === 400 && retryCount < MAX_RETRY_COUNT) {
          timeoutId = setTimeout(() => {
            fetchMediaUrl(call, recordType, retryCount + 1);
          }, POLLING_INTERVAL);
        }
      }
    };

    const process = async () => {
      setMediaUrlToRecord({});

      for (const tab of tabs.filter(x => x.type === RecordTabType.RecordType)) {
        const recordData = tab.data as RecordTabContentData;
        if (recordData.record && [RecordStatus.Available, RecordStatus.Progress].includes(recordData.record.status)) {
          let mediaUrl = recordData.record.mediaUri;
          if (!mediaUrl) {
            const call = recordData.type === 'coaching' ? SessionService.getCoachSessionRecordMedia : SessionService.getFeedbackSessionRecordMedia;
            await fetchMediaUrl(call, recordData.type);
          }
        }
      }
    };

    process();

    // Cleanup timeouts on unmount or when tabs change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [tabs]);


  if (!tabs || !tabs.length) {
    return null;
  }

  useEffect(() => {
    if (onTabSelected) {
      onTabSelected(tabs[selectedTabIndex]);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    if (sessionResult.sessionRecord.coachingSession.setTimeManipulation != lastTimeManipulation) {
      setLastTimeManipulation(sessionResult.sessionRecord.coachingSession.setTimeManipulation);
    }
    if (sessionResult.sessionRecord.coachingSession.setPlaying != playing) {
      setPlaying(sessionResult.sessionRecord.coachingSession.setPlaying);
    }
    if (sessionResult.sessionRecord.coachingSession.setMuted != unMute) {
      setUnMuting(sessionResult.sessionRecord.coachingSession.setMuted);
    }
  }, [sessionResult]);

  const renderRecordTab = (recordData: RecordTabContentData) => {
    if (!recordData.record || !mediaUrlToRecord[recordData.type]) {
      return <div>No record</div>;
    }

    return (
      <RecordPlayer
        record={recordData.record}
        scoreResult={recordData.score}
        teachableMoments={recordData.teachableMoments}
        download={{ url: mediaUrlToRecord[recordData.type] }}
        error={recordData.error}
        isReadonly={recordData.isReadonly}
        watchManipulationInfo={{
          timeInSeconds: lastTimeManipulation ?? 0,
          isMuted: unMute ?? false,
          isPlaying: playing ?? false,
        }}
      />
    );
  };

  return (
    <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
      <TabList
        className={'flex justify-start space-x-8 text-black border-b border-separate text-xl select-none font-bold'}
      >
        {tabs.map(({ title, type }, index) => {
          const highlightRed =
            type === RecordTabType.OtherType &&
            !(sessionEvaluateState.sessionEvaluation as SessionEvaluationResponse).id &&
            selectedTabIndex !== index;
          return (
            <Tab className={`cursor-pointer ${selectedTabIndex == index ? 'text-primary' : ''}`} key={title + index}>
              <div className={highlightRed ? 'text-negative-darker' : ''}>{title}</div>
              <div className={'h-4'} />
              <div
                className={`h-1 w-full bg-primary rounded-full ${selectedTabIndex == index ? '' : 'opacity-0'}`}
              ></div>
            </Tab>
          );
        })}
      </TabList>
      <hr className={'w-full mb-4 border-separate'} />
      {tabs.map(({ type, data }, index) => {
        switch (type) {
          case RecordTabType.RecordType:
            return (
              <TabPanel key={`record-tab-panel-${index}`}>
                {renderRecordTab(data as RecordTabContentData)}
              </TabPanel>
            );

          case RecordTabType.SessionEvaluate:
            return (
              <TabPanel key={`record-tab-panel-${index}`}>
                <MemoizedSessionEvaluateTab
                  sessionEvaluateData={data as SessionEvaluationTabContentData}
                />
              </TabPanel>
            );

          default:
            return (
              <TabPanel key={`record-tab-panel-${index}`}>
                <div>Bad tab type</div>
              </TabPanel>
            );
        }
      })}
    </Tabs>
  );
};

export default RecordsTabs;
