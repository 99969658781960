import React, { useEffect, useRef, useState } from 'react';
import DailyIframe, { DailyCall } from '@daily-co/daily-js';

interface DailyWrapperProps {
  roomUrl: string;
  userName: string;
  userId: string;
  token: string;
}

const DailyManager = {
  callFrame: null as DailyCall | null,
  async create(container: HTMLElement, options: any) {
    if (this.callFrame) {
      await this.destroy();
    }
    this.callFrame = DailyIframe.createFrame(container, options);
    return this.callFrame;
  },
  async destroy() {
    if (this.callFrame) {
      await this.callFrame.destroy();
      this.callFrame = null;
    }
  },
};

const DailyWrapper: React.FC<DailyWrapperProps> = ({ roomUrl, userName, userId, token }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const initializeDaily = async () => {
      if (!containerRef.current || DailyManager.callFrame) return;

      try {
        const frame = await DailyManager.create(containerRef.current, {
          showLeaveButton: false,
          iframeStyle: {
            width: '100%',
            height: '100%',
            border: 'none',
            backgroundColor: 'black',
          },
          theme: {
            colors: {
              accent: '#2c6fae',
            },
          },
        });

        await frame.join({ 
          url: roomUrl,
          userName: userName,
          userData: { userId: userId },
          token: token,
        });

        console.log('Joined Daily call successfully');
      } catch (error) {
        console.error('Failed to create or join Daily call:', error);
      }
    };

    initializeDaily();

    return () => {
      console.log('Cleaning up Daily call');
      DailyManager.destroy();
    };
  }, [roomUrl, userName, userId, token]);

  return (
    <div className="relative h-122.5 w-full">
      <div ref={containerRef} className="absolute inset-0" />
    </div>
  );
};

export default DailyWrapper;
