import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import AbstractModal from '../../components/AbstractModal';
import SessionLogTable from './SessionLogTable';
import ExportXLSForm from './ExportXLSForm';
import { IRouteComponent } from '../../routes';
import { CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import { CaseService } from '../../service/case.service';
import { CategoryService } from '../../service/category.service';
import { CategoryListItemDto } from '../../service/dto/category.dto';
import { SessionService } from '../../service/session.service';
import DefaultHeader from '../../components/DefaultHeader';
import SearchField from '../../components/SearchField';
import Alert from '../../utils/alert';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
  setSessionLogCaseSelector, setSessionLogCategorySelector,
  setSessionLogCoachFilterSelector,
  setSessionLogTraineeFilterSelector
} from "../../redux/actions";

const SessionLogList = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {traineeFilter}: {traineeFilter: string} = useAppSelector((state: any) => state.sessionLogTraineeFilterReducer);
  const {coachFilter}: {coachFilter: string} = useAppSelector((state: any) => state.sessionLogCoachFilterReducer);
  const {selectedCases}: {selectedCases: string[]} = useAppSelector((state: any) => state.sessionLogCaseSelectorReducer);
  const {selectedCategories}: {selectedCategories: string[]} = useAppSelector((state: any) => state.sessionLogCategorySelectorReducer);
  const [caseList, setCaseList] = useState<ICustomSelectedItem[]>([]);
  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);
  const [openedXLSPopup, setOpenedXLSPopup] = useState<boolean>(false);

  const [dateFrom, setDateFrom] = useState<string>();
  const [dateTo, setDateTo] = useState<string>();

  let coachChangeTimer: NodeJS.Timeout | null = null;
  let traineeChangeTimer: NodeJS.Timeout | null = null;
  const searchTimeout = 500;

  useEffect(() => {
    serviceGetCaseList();
    serviceCategoryFetch();
  }, []);

  const handleCaseChange = (caseIds: string[]) => {
    dispatch(setSessionLogCaseSelector({selectedCases: caseIds}));
  };

  const handleTraineeChange = (traineeFilter: string) => {
    if (traineeChangeTimer) {
      clearTimeout(traineeChangeTimer);
    }
    traineeChangeTimer = setTimeout(() => {
      dispatch(setSessionLogTraineeFilterSelector({ traineeFilter: traineeFilter}));
    }, searchTimeout);
  };

  const handleCoachChange = (coachFilter: string) => {
    if (coachChangeTimer) {
      clearTimeout(coachChangeTimer);
    }
    coachChangeTimer = setTimeout(() => {
      dispatch(setSessionLogCoachFilterSelector({ coachFilter: coachFilter}));
    }, searchTimeout);
  };

  const handleCategoryChange = (categoryIds: string[]) => {
    dispatch(setSessionLogCategorySelector({ selectedCategories: categoryIds}));
  };

  const serviceCategoryFetch = () => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setCategoryNames(
          data.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
      },
      () => {},
    );
  };

  const serviceGetCaseList = () => {
    CaseService.listPublished(
      (data: Array<CaseTemplateSummaryDto>) => {
        setCaseList(
          data.map(ct => {
            return { label: ct.name, value: ct.id };
          }),
        );
      },
      () => {},
    );
  };

  const detailsSession = (id: string) => {
    navigate(`/session/result/${id}`);
  };

  const handleXLSPopup = () => {
    setOpenedXLSPopup(!openedXLSPopup);
  };

  const download = () => {
    SessionService.exportSessionLog(
      {
        from: dateFrom,
        to: dateTo,
      },
      async response => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([new Uint8Array(response, 0, response.byteLength)], { type: 'text/csv' }),
        );
        link.download = 'SessionLog.xlsx';
        link.click();
      },
      err => {
        Alert.error('Invalid date range provided');
      },
    );
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={t('history.title')}
        primaryAction={{
          title: t('buttons.exportXLS'),
          onClick: () => {
            handleXLSPopup();
          },
        }}
      />

      <div className='p-6'>
        <div className='grid grid-cols-4 gap-10 mt-6'>
          <CustomMultiSelect
            placeholderAll='All Cases'
            placeholderSelected='Cases Selected'
            list={caseList}
            handleChange={handleCaseChange}
            values={selectedCases}
          />
          <SearchField onSearch={handleCoachChange} placeholderLabel='placeholders.coachFilter' value={coachFilter}/>
          <SearchField onSearch={handleTraineeChange} placeholderLabel='placeholders.traineeFilter' value={traineeFilter}/>
          <CustomMultiSelect
            placeholderAll='All Categories'
            placeholderSelected='Categories'
            list={categoryNames}
            handleChange={handleCategoryChange}
            values={selectedCategories}
          />
        </div>
      </div>

      <SessionLogTable
        selectedCases={selectedCases}
        coachFilter={coachFilter}
        traineeFilter={traineeFilter}
        detailsSession={detailsSession}
        selectedCategories={selectedCategories}
      />

      {openedXLSPopup && (
        <AbstractModal
          label={t('sessionLog.xlsExport.title')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              handleXLSPopup();
            },
          }}
          rightBtn={{
            label: t('buttons.download'),
            onClick: () => {
              download();
            },
          }}
        >
          <ExportXLSForm setDateFrom={setDateFrom} setDateTo={setDateTo} />
        </AbstractModal>
      )}
    </div>
  );
};

export default SessionLogList;
