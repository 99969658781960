import React, { useEffect, useState } from 'react';
import { MediaDeviceDto } from '../../../service/dto/MediaDevice.dto';
import { DeviceState } from '../../../redux/types';
import { DurationMS } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';
import { initialDevicesConfigState } from '../../../redux/reducer';
import { useAppSelector } from '../../../redux/store';

const VideoControl = ({
  localVideoMute,
  localAudioMute,
  remoteAudioPaused,
  onLocalAudioMute,
  onLocalVideoMute,
  onRemoteAudioPlayPause,
  changeCameraOrMic,
  changeSpeaker,
  allowRecording,
  isRecordingActive,
  isUserCoach,
  isSpectator,
  onRecordingClick,
  sessionRecordedTimeInSeconds,
  spectatorList,
}: {
  localVideoMute: boolean;
  localAudioMute: boolean;
  remoteAudioPaused: boolean;
  onLocalVideoMute: () => void;
  onLocalAudioMute: () => void;
  onRemoteAudioPlayPause: () => void;
  changeCameraOrMic: (device: MediaDeviceDto) => void;
  changeSpeaker: (device: MediaDeviceDto) => void;
  isRecordingActive: boolean;
  isUserCoach: boolean;
  isSpectator: boolean;
  allowRecording: boolean;
  onRecordingClick?: () => void;
  sessionRecordedTimeInSeconds: number;
  spectatorList?: Array<{ id: string; firstName: string; lastName: string }>;
}) => {
  const { t } = useTranslation();
  const deviceState: DeviceState = useAppSelector((state: any) => state.devicesConfig);
  const [secondsToDisplay, setSecondsToDisplay] = useState<string>();
  let noDevicesAvailable = false;
  if (deviceState === initialDevicesConfigState) {
    noDevicesAvailable = true;
    localAudioMute = true;
    localVideoMute = true;
  }

  const formatSecondsToDisplay = (seconds: number): string => {
    const time = new Date(seconds * DurationMS.SEC);
    return time.toISOString().substring(11, 19);
  };

  useEffect(() => {
    setSecondsToDisplay(formatSecondsToDisplay(sessionRecordedTimeInSeconds));
  }, [sessionRecordedTimeInSeconds]);

  return (
    <div className='absolute bottom-0 left-0 px-4 pb-3 w-full z-10 flex flex-row justify-between'>
      <div className={'flex items-center'}>
        {isSpectator && (
          <div className='audio-mute relative'>
            <div
              onClick={() => onRemoteAudioPlayPause()}
              className={`py-1.5 px-2 m-2 cursor-pointer w-12 h-12 text-2xl rounded-lg flex justify-center items-center border border-solid ${
                remoteAudioPaused
                  ? 'text-negative border-negative bg-white/75'
                  : 'text-white bg-primary-dark border-primary-dark'
              }`}
            >
              <i className={`bi bi-${remoteAudioPaused ? 'volume-mute' : 'volume-up'}`} />
            </div>
          </div>
        )}
        {!isSpectator && (
          <div className='video-control_local-container-video relative'>
            <div
              onClick={() => onLocalVideoMute()}
              className={`py-1.5 px-2 m-2 cursor-pointer w-12 h-12 text-2xl rounded-lg flex justify-center items-center border border-solid ${
                localVideoMute
                  ? 'text-negative border-negative bg-white/75'
                  : 'text-white bg-primary-dark border-primary-dark'
              }`}
            >
              <i className={`bi bi-${localVideoMute ? 'camera-video-off' : 'camera-video'}`} />
            </div>
            {!noDevicesAvailable && (
              <div className='video-control-list-container camera text-base hidden absolute flex-col bg-white rounded-lg text-black py-2 px-0 shadow bottom-14.5 left-2 w-63'>
                {deviceState.devices &&
                  deviceState.devices
                    .filter(device => device.kind === 'videoinput')
                    .map((device, index) => {
                      return (
                        <div
                          key={`device-control-${index}`}
                          className='hover:bg-primary-lightest flex w-full flex-row justify-between items-center cursor-pointer py-0 px-3 h-10'
                          onClick={() => changeCameraOrMic(device)}
                        >
                          <i className='bi bi-camera-video w-6 h-6 flex justify-center items-center text-2xl' />
                          <div
                            className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${
                              device.active ? 'w-40' : 'w-48'
                            }`}
                          >
                            {device.label}
                          </div>
                          {device.active && (
                            <div>
                              <i className='bi bi-check-lg text-primary' />
                            </div>
                          )}
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        )}
        {!isSpectator && (
          <div className='video-control_local-container-audio relative'>
            <div
              onClick={() => onLocalAudioMute()}
              className={`py-1.5 px-2 m-2 cursor-pointer w-12 h-12 text-2xl rounded-lg flex justify-center items-center border border-solid ${
                localAudioMute
                  ? 'text-negative border-negative bg-white/75'
                  : 'text-white bg-primary-dark border-primary-dark'
              }`}
            >
              <i className={`bi bi-${localAudioMute ? 'mic-mute' : 'mic'}`} />
            </div>
            {!noDevicesAvailable && (
              <div className='video-control-list-container audio text-base hidden absolute flex-col bg-white rounded-lg text-black py-2 px-0 shadow bottom-14.5 left-2 w-63'>
                <div className='relative'>
                  <div className='video-control-audio-list-mic'>
                    <div className='hover:bg-primary-lightest flex w-full flex-row justify-between items-center cursor-pointer py-0 px-3 h-10'>
                      <i className='bi bi-mic-fill audio text-gray w-6 h-6 flex justify-center items-center text-2xl' />
                      <div className='overflow-hidden overflow-ellipsis whitespace-nowrap w-40'>
                        {deviceState.devices &&
                          deviceState.devices
                            .filter((device: MediaDeviceDto) => device.kind === 'audioinput' && device.active)
                            .map(device => device.label)}
                      </div>
                      <i className='bi bi-chevron-right' />
                    </div>
                    <div className='video-control-audio-container mic -top-2 left-63 hidden absolute flex-col rounded-lg bg-white text-black py-2 px-0 w-64 shadow'>
                      {deviceState.devices &&
                        deviceState.devices
                          .filter(device => device.kind === 'audioinput')
                          .map((device, index) => {
                            return (
                              <div
                                key={index}
                                className='mic flex py-0 px-3 h-10 cursor-pointer justify-between items-center hover:bg-primary-lightest'
                                onClick={() => changeCameraOrMic(device)}
                              >
                                <div
                                  className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${
                                    device.active ? 'w-52' : 'w-60'
                                  }`}
                                >
                                  {device.label}
                                </div>
                                {device.active && (
                                  <div>
                                    <i className='bi bi-check-lg text-primary mic' />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                    </div>
                  </div>
                  {deviceState.devices && !!deviceState.devices.filter(device => device.kind === 'audiooutput').length && (
                    <div className='video-control-audio-list-speaker'>
                      <div className='hover:bg-primary-lightest flex w-full flex-row justify-between items-center cursor-pointer py-0 px-3 h-10'>
                        <i className='bi bi-volume-up-fill audio text-gray w-6 h-6 flex justify-center items-center text-2xl' />
                        <div className='overflow-hidden overflow-ellipsis whitespace-nowrap w-40'>
                          {deviceState.devices &&
                            deviceState.devices
                              .filter((device: MediaDeviceDto) => device.kind === 'audiooutput' && device.active)
                              .map(device => device.label)}
                        </div>
                        <i className='bi bi-chevron-right' />
                      </div>
                      <div className='video-control-audio-container speaker -top-2 left-63 hidden absolute flex-col rounded-lg bg-white text-black py-2 px-0 w-64 shadow'>
                        {deviceState.devices &&
                          deviceState.devices
                            .filter(device => device.kind === 'audiooutput')
                            .map((device, index) => {
                              return (
                                <div
                                  key={index}
                                  className='flex py-0 px-3 h-10 cursor-pointer justify-between items-center hover:bg-primary-lightest'
                                  onClick={() => changeSpeaker(device)}
                                >
                                  <div
                                    className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${
                                      device.active ? 'w-52' : 'w-60'
                                    }`}
                                  >
                                    {device.label}
                                  </div>
                                  {device.active && (
                                    <div>
                                      <i className='bi bi-check-lg text-primary speaker' />
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {!isSpectator && spectatorList && (
          <div className='spectator-count relative'>
            <div
              className={`py-1.5 px-2 m-2 cursor-pointer w-20 h-12 text-2xl rounded-lg flex justify-center items-center border border-solid text-white bg-primary-dark border-primary-dark`}
            >
              <i className={`bi bi-eye`} />
              &nbsp;
              {spectatorList.length}
              {spectatorList.length ? (
                <div className='spectator-list-container text-base hidden absolute flex-col bg-white rounded-lg text-black py-2 px-0 shadow bottom-14.5 left-2 w-63'>
                  {spectatorList.map((spectator, index) => {
                    return (
                      <div
                        key={`spectator-control-${index}-1`}
                        className='hover:bg-primary-lightest flex w-full flex-row justify-between items-center cursor-pointer py-0 px-3 h-10'
                      >
                        <div className='overflow-hidden overflow-ellipsis whitespace-nowrap w-48'>
                          {spectator.firstName}&nbsp;{spectator.lastName}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        )}
      </div>
      <div className={'space-x-2 flex items-center'}>
        {!isRecordingActive && !allowRecording ? (
          <div className={'relative bg-negative rounded-lg px-4 text-white'}>Recording complete.</div>
        ) : (
          <>
            {isRecordingActive && (
              <div className={'relative bg-negative rounded-lg px-4 text-white'}>{secondsToDisplay}</div>
            )}

            {isUserCoach && onRecordingClick ? (
              <div className={'relative group'}>
                <div
                  className={`m-2 p-2 w-12 h-12 text-2xl rounded-full flex flex-col justify-center border bolder-solid border-negative cursor-pointer`}
                  onClick={onRecordingClick}
                >
                  <div className={'w-full flex justify-center'}>
                    <div className={`bg-negative ${isRecordingActive ? 'rounded h-5 w-5' : 'rounded-full w-8 h-8'}`} />
                  </div>
                </div>
                <span className='z-50 absolute rounded-lg text-center bg-gray-dark text-white -top-10 right-0 whitespace-nowrap px-2 py-1 text-tiny font-thin opacity-0 group-hover:opacity-90'>
                  {isRecordingActive ? "STOP" : "START"}
                </span>
              </div>
            ) : (
              isRecordingActive && (
                <div className={'relative group cursor-not-allowed'}>
                  <div
                    className={`m-2 p-2 w-12 h-12 text-2xl rounded-full flex flex-col justify-center border bolder-solid border-negative pointer-events-none`}
                  >
                    <div className={'w-full flex justify-center'}>
                      <div className={'bg-negative rounded h-5 w-5'} />
                    </div>
                  </div>
                </div>
              )
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default VideoControl;
